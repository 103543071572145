import Defaults from 'mangools-commons/dist/constants/Defaults';
import isNil from 'ramda/src/isNil';
import isEmpty from 'ramda/src/isEmpty';

export const parseIntWithNVFallback = value => {
    if (isNil(value)) {
        return Defaults.NO_VALUE;
    } else {
        return parseInt(value, 10);
    }
};

export const parseIntWithFallback = value => {
    if (isNil(value)) {
        return Defaults.NOT_AVAILABLE;
    } else {
        return parseInt(value, 10);
    }
};

export const parseFloatWithFallback = value => {
    if (isNil(value)) {
        return Defaults.NOT_AVAILABLE;
    } else {
        return parseFloat(value);
    }
};

export const parseStringWithFallback = value => {
    if (isNil(value) || isEmpty(value)) {
        return Defaults.NOT_AVAILABLE;
    } else {
        return value;
    }
};

export const decodeUrl = rawUrl => {
    try {
        const decodedUrl = decodeURIComponent(rawUrl);
        return decodedUrl;
    } catch (e) {
        // NOTE:
        // i.e. malformed URI error
        // we are fallbacking to the original URL, it should come from SERP so we believe in it :)
        return rawUrl;
    }
};

export const parseLocationLabel = value => {
    if (!isNil(value) && typeof value === 'string') {
        return value.replace(/,/g, ', ');
    } else {
        return null;
    }
};
