import React from 'react';
import { func, number, string, bool } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

import { DESKTOP, MOBILE } from 'mangools-commons/dist/constants/Platforms';
import KeywordDifficultyRank from 'components/other/KeywordDifficultyRank';

const HistoryPanelItem = React.memo(props => {
    const handleClick = () => {
        props.onClick({
            keyword: props.keyword,
            locationId: props.locationId,
            platformId: props.platformId,
        });
    };

    const renderPlatform = platformId => {
        if (platformId === MOBILE.id) {
            return (
                <span className="mg-truncate font-12 mg-margin-l-10">
                    <FontAwesomeIcon icon="mobile-alt" className="color-grey mg-margin-r-5" />
                    {MOBILE.label}
                </span>
            );
        } else if (platformId === DESKTOP.id) {
            return (
                <span className="mg-truncate font-12 mg-margin-l-10">
                    <FontAwesomeIcon icon={DESKTOP.iconName} className="color-grey mg-margin-r-5" />
                    {DESKTOP.label}
                </span>
            );
        } else {
            return null;
        }
    };

    const rowClasses = classnames('mg-results-tr', 'uk-flex', 'cursor-pointer', {
        'is-preselected': props.isPreselected,
    });

    /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
    return (
        <div className={rowClasses} onClick={handleClick} role="listitem">
            <div className="mg-results-td is-80 uk-flex uk-flex-middle">
                <KeywordDifficultyRank
                    rank={props.keywordDifficulty}
                    tooltipPlacement="right"
                    updatedAt={props.kdUpdatedAt}
                />
            </div>
            <div className="mg-results-td uk-width-8-10 mg-truncate">
                <span className="font-16">{props.keyword}</span>
                <div className="mg-truncate">
                    <span className="mg-truncate font-12">
                        <FontAwesomeIcon icon="map-marker-alt" className="color-grey mg-margin-r-5" />
                        {props.locationLabel}
                    </span>
                    {renderPlatform(props.platformId)}
                </div>
            </div>
        </div>
    );
    /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
});

HistoryPanelItem.propTypes = {
    kdUpdatedAt: number.isRequired,
    keyword: string.isRequired,
    keywordDifficulty: number.isRequired,
    locationId: number.isRequired,
    locationLabel: string.isRequired,
    onClick: func.isRequired,
    platformId: number.isRequired,
    isPreselected: bool.isRequired,
};

export default HistoryPanelItem;
