import React, { Component } from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import { number, string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import isNil from 'ramda/src/isNil';

import Defaults from 'mangools-commons/dist/constants/Defaults';
import RankColorizerService from 'mangools-commons/dist/services/RankColorizerService';

import Tooltip from 'components/reusable/Tooltip';
import DateFormatterService from 'services/DateFormatterService';
import Other from 'constants/Other';

class KeywordDifficultyRank extends Component {
    constructor() {
        super();
        this.renderRank = this.renderRank.bind(this);
        this.renderSearchIcon = this.renderSearchIcon.bind(this);
    }

    shouldComponentUpdate(newProps, newState) {
        return shallowCompare(this, newProps, newState);
    }

    isOutDatedRank() {
        if (isNil(this.props.updatedAt) || this.props.updatedAt === Defaults.NO_VALUE) {
            return true;
        } else {
            const rankUpdatedTs = this.props.updatedAt * 1000;
            const cacheValidTs = new Date().getTime() - Other.KD_CACHE_TIME_DAYS * 24 * 60 * 60 * 1000;

            if (rankUpdatedTs < Other.NEW_KD_ALGO_DEPLOY_TS || rankUpdatedTs < cacheValidTs) {
                // KD is either calculated before algo update or is older than 30 days (cache time)
                return true;
            } else {
                // KD is fresh
                return false;
            }
        }
    }

    renderTooltip() {
        let dateString = '';

        if (isNil(this.props.updatedAt) || this.props.updatedAt === Defaults.NO_VALUE) {
            dateString = Defaults.NOT_AVAILABLE;
        } else {
            dateString = DateFormatterService.relativeAgo(this.props.updatedAt * 1000);
        }

        return (
            <span>
                Last calculated:&nbsp;<strong>{dateString}</strong>
            </span>
        );
    }

    renderRank() {
        const classes = classnames('mg-rank', RankColorizerService.getColor(this.props.rank));

        return (
            <Tooltip overlayContent={this.renderTooltip()} placement={this.props.tooltipPlacement}>
                <span className={classes} style={{ opacity: this.isOutDatedRank() ? 0.5 : 1.0 }}>
                    <strong>{this.props.rank}</strong>
                </span>
            </Tooltip>
        );
    }

    renderSearchIcon() {
        const classes = classnames('mg-rank', 'no-rank', 'uk-text-center');

        return (
            <span className={classes}>
                <FontAwesomeIcon icon="search" />
            </span>
        );
    }

    render() {
        return this.props.rank > 0 ? this.renderRank() : this.renderSearchIcon();
    }
}

KeywordDifficultyRank.propTypes = {
    rank: number.isRequired,
    tooltipPlacement: string,
    updatedAt: number.isRequired,
};

export default KeywordDifficultyRank;
