import isNil from 'ramda/src/isNil';
import Defaults from 'mangools-commons/dist/constants/Defaults';
import { decodeUrl, parseIntWithFallback, parseFloatWithFallback } from 'sources/parsers/commons';
import MetricPropertyNames from 'constants/MetricPropertyNames';
import Other from 'constants/Other';

const isMajesticPresent = item => !isNil(item.m.majestic);
const isMozPresent = item => !isNil(item.m.moz);

/* eslint-disable max-len */
export const parse = item => ({
    domain: item.domain,
    id: item._id, // eslint-disable-line no-underscore-dangle
    linkProfileStrength: parseIntWithFallback(item.m.rank.v.r),
    [MetricPropertyNames.acRank]: isMajesticPresent(item)
        ? parseIntWithFallback(item.m.majestic.v.ACRank)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.topRank]: !isNil(item.topRank) ? parseInt(item.topRank, 10) : Other.MAX_TOP_RANK,
    [MetricPropertyNames.authorityLinks]: isMozPresent(item)
        ? parseIntWithFallback(item.m.moz.v.ueid)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.citationFlow]: isMajesticPresent(item)
        ? parseIntWithFallback(item.m.majestic.v.CitationFlow)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.domainAuthority]: isMozPresent(item)
        ? parseFloatWithFallback(item.m.moz.v.pda)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.externalBackLinksEduExact]: isMajesticPresent(item)
        ? parseIntWithFallback(item.m.majestic.v.ExtBackLinksEDU_Exact)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.externalBackLinksEdu]: isMajesticPresent(item)
        ? parseIntWithFallback(item.m.majestic.v.ExtBackLinksEDU)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.externalBackLinksGovExact]: isMajesticPresent(item)
        ? parseIntWithFallback(item.m.majestic.v.ExtBackLinksGOV_Exact)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.externalBackLinksGov]: isMajesticPresent(item)
        ? parseIntWithFallback(item.m.majestic.v.ExtBackLinksGOV)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.externalBackLinks]: isMajesticPresent(item)
        ? parseIntWithFallback(item.m.majestic.v.ExtBackLinks)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.externalLinks]: isMozPresent(item)
        ? parseIntWithFallback(item.m.moz.v.ued)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.externalLinksToRoot]: isMozPresent(item)
        ? parseIntWithFallback(item.m.moz.v.ped)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.externalLinksToSubdomain]: isMozPresent(item)
        ? parseIntWithFallback(item.m.moz.v.fed)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.facebookShares]: parseIntWithFallback(item.m.fb.v.l),
    [MetricPropertyNames.indexedURLs]: isMajesticPresent(item)
        ? parseIntWithFallback(item.m.majestic.v.IndexedURLs)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.linkingCBlocks]: isMozPresent(item)
        ? parseIntWithFallback(item.m.moz.v.pib)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.links]: isMozPresent(item) ? parseIntWithFallback(item.m.moz.v.uid) : Defaults.NO_VALUE,
    [MetricPropertyNames.linksToRoot]: isMozPresent(item) ? parseIntWithFallback(item.m.moz.v.puid) : Defaults.NO_VALUE,
    [MetricPropertyNames.linksToSubdomain]: isMozPresent(item)
        ? parseIntWithFallback(item.m.moz.v.fuid)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.mozRankExternalEquity]: isMozPresent(item)
        ? parseFloatWithFallback(item.m.moz.v.uemrp)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.mozRank]: isMozPresent(item) ? parseFloatWithFallback(item.m.moz.v.umrp) : Defaults.NO_VALUE,
    [MetricPropertyNames.mozRankRootCombined]: isMozPresent(item)
        ? parseFloatWithFallback(item.m.moz.v.fjp)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.mozRankRootExternalEquity]: isMozPresent(item)
        ? parseFloatWithFallback(item.m.moz.v.pejp)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.mozRankRoot]: isMozPresent(item)
        ? parseFloatWithFallback(item.m.moz.v.pmrp)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.mozRankSubdomainCombined]: isMozPresent(item)
        ? parseFloatWithFallback(item.m.moz.v.pjp)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.mozRankSubdomainExternalEquity]: isMozPresent(item)
        ? parseFloatWithFallback(item.m.moz.v.fejp)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.mozRankSubdomain]: isMozPresent(item)
        ? parseFloatWithFallback(item.m.moz.v.fmrp)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.mozTrust]: isMozPresent(item) ? parseFloatWithFallback(item.m.moz.v.utrp) : Defaults.NO_VALUE,
    [MetricPropertyNames.mozTrustRoot]: isMozPresent(item)
        ? parseFloatWithFallback(item.m.moz.v.ptrp)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.mozTrustSubdomain]: isMozPresent(item)
        ? parseFloatWithFallback(item.m.moz.v.ftrp)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.outDomainsExternal]: isMajesticPresent(item)
        ? parseIntWithFallback(item.m.majestic.v.OutDomainsExternal)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.outLinksExternal]: isMajesticPresent(item)
        ? parseIntWithFallback(item.m.majestic.v.OutLinksExternal)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.outLinksInternal]: isMajesticPresent(item)
        ? parseIntWithFallback(item.m.majestic.v.OutLinksInternal)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.pageAuthority]: isMozPresent(item)
        ? parseFloatWithFallback(item.m.moz.v.upa)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.refDomainsEduExact]: isMajesticPresent(item)
        ? parseIntWithFallback(item.m.majestic.v.RefDomainsEDU_Exact)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.refDomainsEdu]: isMajesticPresent(item)
        ? parseIntWithFallback(item.m.majestic.v.RefDomainsEDU)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.refDomainsGovExact]: isMajesticPresent(item)
        ? parseIntWithFallback(item.m.majestic.v.RefDomainsGOV_Exact)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.refDomainsGov]: isMajesticPresent(item)
        ? parseIntWithFallback(item.m.majestic.v.RefDomainsGOV)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.refDomains]: isMajesticPresent(item)
        ? parseIntWithFallback(item.m.majestic.v.RefDomains)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.refIPs]: isMajesticPresent(item)
        ? parseIntWithFallback(item.m.majestic.v.RefIPs)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.refSubNets]: isMajesticPresent(item)
        ? parseIntWithFallback(item.m.majestic.v.RefSubNets)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.rootDomainRootDomainsLinking]: isMozPresent(item)
        ? parseIntWithFallback(item.m.moz.v.pid)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.rootDomainsLinking]: isMozPresent(item)
        ? parseIntWithFallback(item.m.moz.v.uipl)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.rootDomainsLinkingToSubdomain]: isMozPresent(item)
        ? parseIntWithFallback(item.m.moz.v.fipl)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.spamScoreSubdomain]: isMozPresent(item)
        ? parseIntWithFallback(item.m.moz.v.fspsc)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.subdomainExternalLinks]: isMozPresent(item)
        ? parseIntWithFallback(item.m.moz.v.feid)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.subdomainLinking]: isMozPresent(item)
        ? parseIntWithFallback(item.m.moz.v.uifq)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.subdomainLinkingToSubdomain]: isMozPresent(item)
        ? parseIntWithFallback(item.m.moz.v.fid)
        : Defaults.NO_VALUE,
    [MetricPropertyNames.trustFlow]: isMajesticPresent(item)
        ? parseIntWithFallback(item.m.majestic.v.TrustFlow)
        : Defaults.NO_VALUE,
    url: decodeUrl(item.url),
});
/* eslint-enable max-len */
