import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { GlobalHotKeys } from 'react-hotkeys';

import ShortcutService from 'mangools-commons/dist/services/ShortcutService';

import Overlay from 'components/layout/Overlay';
import MessageContainer from 'components/messages/MessageContainer';
import ModalContainer from 'components/modals/ModalContainer';
import NavbarContainer from 'components/layout/navbar/NavbarContainer';
import PanelContainer from 'components/panels/PanelContainer';
import TopLevelMessageContainer from 'components/messages/topLevel/TopLevelMessageContainer';

import shortcuts, { CLOSE_ALL, SHOW_SHORTCUTS_TOGGLE } from 'constants/Shortcuts';

import { closeAll, showShortcutsMessage } from 'actions/uiActions';

import { newUserMessageVisibilitySelector } from 'selectors/userSelectors';
import { announcementMesageVisibilitySelector } from 'selectors/announcementsSelectors';

import {
    anyOverlayVisibleSelector,
    mobileOverlayVisibilitySelector,
    onlineStatusSelector,
    overlayVisibilitySelector,
} from 'selectors/uiSelectors';
import { Alert } from 'mangools-react-components/src';

const LayoutContainer = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    const mobileOverlayVisibility = useSelector(mobileOverlayVisibilitySelector);
    const onlineStatus = useSelector(onlineStatusSelector);
    const overlayVisibility = useSelector(overlayVisibilitySelector);
    const topLevelMessageVisible = useSelector(
        state => newUserMessageVisibilitySelector(state) || announcementMesageVisibilitySelector(state),
    );
    const anyOverlayVisible = useSelector(anyOverlayVisibleSelector);
    const areShortcutsDisabled = useSelector(anyOverlayVisibleSelector);

    const appClasses = classnames({
        'serpchecker-app': true,
        'is-offline': onlineStatus === false,
        'is-messaged': topLevelMessageVisible,
        'is-modal-opened': anyOverlayVisible,
    });

    const renderShortcuts = () => {
        const { keyMap, handlers } = ShortcutService.getShortcutMapAndHandlers({
            handlers: {
                [CLOSE_ALL]: { func: () => dispatch(closeAll()), preventDisable: true },
                [SHOW_SHORTCUTS_TOGGLE]: { func: () => dispatch(showShortcutsMessage()) },
            },
            areShortcutsDisabled,
            shortcuts,
        });

        return <GlobalHotKeys allowChanges keyMap={keyMap} handlers={handlers} />;
    };

    return (
        <div className={appClasses}>
            {renderShortcuts()}
            <TopLevelMessageContainer />
            <NavbarContainer currentRoute={location.pathname} />
            {/*<IssueWarningBanner />*/}
            <Outlet />
            <PanelContainer />
            <ModalContainer />
            <MessageContainer />
            <Overlay mobile onClick={() => dispatch(closeAll())} visibility={mobileOverlayVisibility} />
            <Overlay mobile={false} onClick={() => dispatch(closeAll())} visibility={overlayVisibility} />
            <div className="uk-hidden-small">
                <Alert />
            </div>
        </div>
    );
};

export default LayoutContainer;
