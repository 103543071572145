import isNil from 'ramda/src/isNil';
import uniq from 'ramda/src/uniq';
import update from 'immutability-helper';

import PlatformFinderService from 'mangools-commons/dist/services/PlatformFinderService';
import { REHYDRATE } from 'redux-persist/constants';
import { DEFAULT_IDS } from 'constants/Metrics';
import { DESKTOP } from 'mangools-commons/dist/constants/Platforms';
import { BASIC_LOCATIONS } from 'mangools-commons/dist/constants/Locations';

import ActionTypes from 'constants/ActionTypes';

const [US] = BASIC_LOCATIONS;

const initialState = {
    keyword: '',
    location: US,
    platform: DESKTOP,
    selectedMetricIds: DEFAULT_IDS,
};

const defaultsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.UI_DEFAULTS_ALL_SET: {
            return update(state, {
                keyword: { $set: action.payload.keyword },
                location: { $set: action.payload.location },
                platform: { $set: action.payload.platform },
            });
        }
        case ActionTypes.UI_DEFAULTS_KEYWORD_SET: {
            return update(state, {
                keyword: { $set: action.payload },
            });
        }
        case ActionTypes.UI_DEFAULTS_PLATFORM_ID_SET: {
            return update(state, {
                platform: { $set: PlatformFinderService.findById(action.payload) },
            });
        }
        case ActionTypes.UI_DEFAULTS_LOCATION_SET: {
            return update(state, {
                location: { $set: action.payload },
            });
        }
        case ActionTypes.UI_DEFAULTS_SELECTED_METRICS_SET: {
            return update(state, {
                selectedMetricIds: { $set: uniq(action.payload) },
            });
        }
        case REHYDRATE: {
            const savedState = action.payload.ui;

            if (!isNil(savedState) && !isNil(savedState.defaults)) {
                return update(state, {
                    location: { $set: savedState.defaults.location },
                    platform: { $set: savedState.defaults.platform },
                    selectedMetricIds: { $set: savedState.defaults.selectedMetricIds },
                });
            } else {
                return state;
            }
        }
        default: {
            return state;
        }
    }
};

export default defaultsReducer;
