import Defaults from 'mangools-commons/dist/constants/Defaults';
import Other from 'constants/Other';

class DateFormatterService {
    /* eslint-disable no-mixed-operators */
    /* eslint-disable prefer-template */
    static relativeAgo(timestamp) {
        const date = new Date(timestamp);
        const diff = (new Date().getTime() - date.getTime()) / 1000;
        const hourDiff = Math.floor(diff / 3600);
        const dayDiff = Math.floor(diff / 86400);

        if (Number.isNaN(dayDiff)) {
            return Defaults.NOT_AVAILABLE;
        } else if (dayDiff >= 31) {
            return '> 1 month ago';
        } else {
            return (
                (hourDiff < 1 && Other.JUST_NOW) ||
                (hourDiff < 24 && `${hourDiff} ${hourDiff === 1 ? 'hour' : 'hours'} ago`) ||
                (dayDiff < 31 && `${dayDiff} ${dayDiff === 1 ? 'day' : 'days'} ago`)
            );
        }
    }
    /* eslint-enable no-mixed-operators */
    /* eslint-enable prefer-template */
}

export default DateFormatterService;
