import React from 'react';
import { eventChannel } from 'redux-saga';
import { call, put, select, spawn, takeEvery, takeLatest } from 'redux-saga/effects';
import isNil from 'ramda/src/isNil';
import ColorSchemes from 'mangools-commons/dist/constants/ColorSchemes';
import DOMService from 'mangools-commons/dist/services/DOMService';

import {
    receivedErrorNotificationAction,
    receivedInfoNotificationAction,
    requestedCloseAllNotificationsAction,
    setComparingBoxScrollLeft,
    setOnlineStatus,
    setColorScheme,
} from 'actions/uiActions';
import { gtmTrack } from 'actions/analyticsActions';

import { onlineStatusSelector, colorSchemeSelector } from 'selectors/uiSelectors';

import ActionTypes from 'constants/ActionTypes';
import ElementIds from 'constants/ElementIds';
import { analyticsEvents } from 'constants/analytics';
import { toast } from 'react-toastify';

export function* showErrorNotification(message, config = {}) {
    yield put(receivedErrorNotificationAction(message, config));
    yield call(toast.error, message, config);
}

export function* showInfoNotification(message, config = {}) {
    yield put(receivedInfoNotificationAction(message, config));
    yield call(toast.info, message, config);
}

export function* closeAllNotifications() {
    yield put(requestedCloseAllNotificationsAction());
    yield call(toast.dismiss);
}

function* updateOnlineStatus() {
    const currentStatus = yield select(onlineStatusSelector);
    const newStatus = window.navigator.onLine;
    yield put(setOnlineStatus({ onlineStatus: newStatus }));

    if (currentStatus === true && newStatus === false) {
        yield call(closeAllNotifications);
        // NOTE: Went from online to offline, show error notification
        yield call(showErrorNotification, <div>You have lost your <strong>internet connection</strong>!</div>);
    } else if (currentStatus === false && newStatus === true) {
        yield call(closeAllNotifications);
        // NOTE: Went from offline to online, show success notification
        yield call(showInfoNotification, <div>You are now <strong>online</strong>!</div>);
    }
}

function* syncResultAndComparingTables(action) {
    const { horizontalPosition, source } = action.payload;
    const mainResultTable = document.getElementById(ElementIds.RESULT_TABLE_WRAPPER_ID);
    const comparingResultItem = document.getElementById(ElementIds.COMPARING_RESULT_WRAPPER_ID);

    if (source === ElementIds.RESULT_TABLE_WRAPPER_ID && !isNil(comparingResultItem)) {
        comparingResultItem.scrollLeft = horizontalPosition;
    } else if (source === ElementIds.COMPARING_RESULT_WRAPPER_ID && !isNil(mainResultTable)) {
        mainResultTable.scrollLeft = horizontalPosition;
    }

    yield put(setComparingBoxScrollLeft(horizontalPosition));
}

function* updateColorSchemeFromChannel(channel) {
    const colorScheme = yield select(colorSchemeSelector);

    if (colorScheme === ColorSchemes.AUTO) {
        if (DOMService.isDarkMode(channel)) {
            DOMService.darkModeOn();
        } else {
            DOMService.darkModeOff();
        }

        // NOTE: this triggers force rerender in components to check for dark mode
        yield put(setColorScheme(ColorSchemes.LIGHT));
        yield put(setColorScheme(ColorSchemes.AUTO));
    }
}

function* updateColorScheme({ payload }) {
    if (DOMService.isDarkMode(payload)) {
        DOMService.darkModeOn();
    } else {
        DOMService.darkModeOff();
    }

    yield put(
        gtmTrack({
            action: payload,
            event: analyticsEvents.THEME_TOGGLE,
        }),
    );
}

// CHANNELS

function onlineStatusChannels() {
    return eventChannel(emitter => {
        window.addEventListener('online', emitter);
        window.addEventListener('offline', emitter);

        return () => {
            window.removeEventListener('online', emitter);
            window.removeEventListener('offline', emitter);
        };
    });
}

function preferredColorSchemeChannel() {
    return eventChannel(emitter => {
        const mql = DOMService.registerMqlEventListener(colorScheme => emitter(colorScheme));
        return () => DOMService.removeMqlEventListener(mql);
    });
}

// WATCHERS

function* watchOnlineStatusByEvents() {
    const channel = yield call(onlineStatusChannels);
    yield takeEvery(channel, updateOnlineStatus);
}

export function* watchResultAndComparingTablesHorizontalSyncRequests() {
    yield takeLatest(
        ActionTypes.UI_MISC_RESULT_AND_COMPARING_TABLES_HORIZONTAL_SCROLL_SYNC_REQUESTED,
        syncResultAndComparingTables,
    );
}

function* watchPreferredColorSchemeByEvents() {
    const channel = yield call(preferredColorSchemeChannel);
    yield takeEvery(channel, updateColorSchemeFromChannel);
}

function* watchPreferredColorSchemeChange() {
    yield takeEvery(ActionTypes.UI_MISC_COLOR_SCHEME_SET, updateColorScheme);
}

export function* watchUIRequests() {
    yield spawn(watchOnlineStatusByEvents);
    yield spawn(watchResultAndComparingTablesHorizontalSyncRequests);
    yield spawn(watchPreferredColorSchemeByEvents);
    yield spawn(watchPreferredColorSchemeChange);
}
