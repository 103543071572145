import Defaults from 'mangools-commons/dist/constants/Defaults';

class SerpFeaturesImpactService {
    static getColor(value) {
        switch (value) {
            case 0: {
                return 'green';
            }
            case 1: {
                return 'green';
            }
            case 2: {
                return 'lightgreen';
            }
            case 3: {
                return 'yellow';
            }
            case 4: {
                return 'orange';
            }
            case 5: {
                return 'red';
            }
            default: {
                return 'green';
            }
        }
    }

    static getLabel(value) {
        switch (value) {
            case 0: {
                return 'minimal';
            }
            case 1: {
                return 'very low';
            }
            case 2: {
                return 'low';
            }
            case 3: {
                return 'average';
            }
            case 4: {
                return 'high';
            }
            case 5: {
                return 'very high';
            }
            default: {
                return Defaults.NOT_AVAILABLE;
            }
        }
    }
}

export default SerpFeaturesImpactService;
