import { combineReducers } from 'redux';

import announcementsReducer from 'reducers/announcementsReducer';
import comparingBoxReducer from 'reducers/ui/comparingBoxReducer';
import defaultsReducer from 'reducers/ui/defaultsReducer';
import dropdownsReducer from 'reducers/ui/dropdownsReducer';
import exportReducer from 'reducers/exportReducer';
import historyReducer from 'reducers/historyReducer';
import locationReducer from 'reducers/locationReducer';
import messagesReducer from 'reducers/ui/messagesReducer';
import miscReducer from 'reducers/ui/miscReducer';
import modalsReducer from 'reducers/ui/modalsReducer';
import panelsReducer from 'reducers/ui/panelsReducer';
import paramsReducer from 'reducers/paramsReducer';
import resultReducer from 'reducers/resultReducer';
import snapshotReducer from 'reducers/snapshotReducer';
import urlDataReducer from 'reducers/urlDataReducer';
import userReducer from 'reducers/userReducer';

const rootReducer = combineReducers({
    announcements: announcementsReducer,
    export: exportReducer,
    history: historyReducer,
    locations: locationReducer,
    params: paramsReducer,
    results: resultReducer,
    snapshot: snapshotReducer,
    ui: combineReducers({
        comparingBox: comparingBoxReducer,
        defaults: defaultsReducer,
        dropdowns: dropdownsReducer,
        messages: messagesReducer,
        misc: miscReducer,
        modals: modalsReducer,
        panels: panelsReducer,
    }),
    urlData: urlDataReducer,
    user: userReducer,
});

export default rootReducer;
