import update from 'immutability-helper';
import ActionTypes from 'constants/ActionTypes';

import Defaults from 'mangools-commons/dist/constants/Defaults';
import MetricPropertyNames from 'constants/MetricPropertyNames';
import Other from 'constants/Other';

const DEFAULT_DATA = {
    domain: '',
    id: null,
    linkProfileStrength: Defaults.NO_VALUE,
    [MetricPropertyNames.acRank]: Defaults.NO_VALUE,
    [MetricPropertyNames.topRank]: Other.MAX_TOP_RANK,
    [MetricPropertyNames.authorityLinks]: Defaults.NO_VALUE,
    [MetricPropertyNames.citationFlow]: Defaults.NO_VALUE,
    [MetricPropertyNames.domainAuthority]: Defaults.NO_VALUE,
    [MetricPropertyNames.externalBackLinksEduExact]: Defaults.NO_VALUE,
    [MetricPropertyNames.externalBackLinksEdu]: Defaults.NO_VALUE,
    [MetricPropertyNames.externalBackLinksGovExact]: Defaults.NO_VALUE,
    [MetricPropertyNames.externalBackLinksGov]: Defaults.NO_VALUE,
    [MetricPropertyNames.externalBackLinks]: Defaults.NO_VALUE,
    [MetricPropertyNames.externalLinks]: Defaults.NO_VALUE,
    [MetricPropertyNames.externalLinksToRoot]: Defaults.NO_VALUE,
    [MetricPropertyNames.externalLinksToSubdomain]: Defaults.NO_VALUE,
    [MetricPropertyNames.facebookShares]: Defaults.NO_VALUE,
    [MetricPropertyNames.indexedURLs]: Defaults.NO_VALUE,
    [MetricPropertyNames.linkingCBlocks]: Defaults.NO_VALUE,
    [MetricPropertyNames.links]: Defaults.NO_VALUE,
    [MetricPropertyNames.linksToRoot]: Defaults.NO_VALUE,
    [MetricPropertyNames.linksToSubdomain]: Defaults.NO_VALUE,
    [MetricPropertyNames.mozRankExternalEquity]: Defaults.NO_VALUE,
    [MetricPropertyNames.mozRank]: Defaults.NO_VALUE,
    [MetricPropertyNames.mozRankRootCombined]: Defaults.NO_VALUE,
    [MetricPropertyNames.mozRankRootExternalEquity]: Defaults.NO_VALUE,
    [MetricPropertyNames.mozRankRoot]: Defaults.NO_VALUE,
    [MetricPropertyNames.mozRankSubdomainCombined]: Defaults.NO_VALUE,
    [MetricPropertyNames.mozRankSubdomainExternalEquity]: Defaults.NO_VALUE,
    [MetricPropertyNames.mozRankSubdomain]: Defaults.NO_VALUE,
    [MetricPropertyNames.mozTrust]: Defaults.NO_VALUE,
    [MetricPropertyNames.mozTrustRoot]: Defaults.NO_VALUE,
    [MetricPropertyNames.mozTrustSubdomain]: Defaults.NO_VALUE,
    [MetricPropertyNames.outDomainsExternal]: Defaults.NO_VALUE,
    [MetricPropertyNames.outLinksExternal]: Defaults.NO_VALUE,
    [MetricPropertyNames.outLinksInternal]: Defaults.NO_VALUE,
    [MetricPropertyNames.pageAuthority]: Defaults.NO_VALUE,
    [MetricPropertyNames.refDomainsEduExact]: Defaults.NO_VALUE,
    [MetricPropertyNames.refDomainsEdu]: Defaults.NO_VALUE,
    [MetricPropertyNames.refDomainsGovExact]: Defaults.NO_VALUE,
    [MetricPropertyNames.refDomainsGov]: Defaults.NO_VALUE,
    [MetricPropertyNames.refDomains]: Defaults.NO_VALUE,
    [MetricPropertyNames.refIPs]: Defaults.NO_VALUE,
    [MetricPropertyNames.refSubNets]: Defaults.NO_VALUE,
    [MetricPropertyNames.rootDomainRootDomainsLinking]: Defaults.NO_VALUE,
    [MetricPropertyNames.rootDomainsLinking]: Defaults.NO_VALUE,
    [MetricPropertyNames.rootDomainsLinkingToSubdomain]: Defaults.NO_VALUE,
    [MetricPropertyNames.spamScoreSubdomain]: Defaults.NO_VALUE,
    [MetricPropertyNames.subdomainExternalLinks]: Defaults.NO_VALUE,
    [MetricPropertyNames.subdomainLinking]: Defaults.NO_VALUE,
    [MetricPropertyNames.subdomainLinkingToSubdomain]: Defaults.NO_VALUE,
    [MetricPropertyNames.trustFlow]: Defaults.NO_VALUE,
    url: '',
};

const initialState = {
    data: DEFAULT_DATA,
    error: {
        status: null,
        text: null,
    },
    fetching: false,
};

const urlDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.DATA_URL_DATA_FETCHING: {
            return update(state, {
                fetching: { $set: true },
            });
        }
        case ActionTypes.DATA_URL_DATA_RECEIVED: {
            return update(state, {
                fetching: { $set: false },
                data: { $set: action.payload },
            });
        }
        case ActionTypes.DATA_URL_DATA_ERROR: {
            return update(state, {
                data: { $set: initialState.data },
                fetching: { $set: false },
                error: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                },
            });
        }
        default: {
            return state;
        }
    }
};

export default urlDataReducer;
