import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import FullAppPreloader from 'components/layout/FullAppPreloader';
import LayoutContainer from 'components/layout/LayoutContainer';
import AsyncLoaderErrorBoundaryContainer
    from 'mangools-react-components/src/components/AsyncLoaderErrorBoundaryContainer';

import RoutePaths from 'constants/RoutePaths';
import { RouterEventListener } from 'mangools-react-components/src';
import ActionTypes from '../constants/ActionTypes';

const DashboardContainer = lazy(() => import('components/dashboard/DashboardContainer'));
const HomeContainer = lazy(() => import('components/home/HomeContainer'));
const NotFound = lazy(() => import('components/other/NotFound'));

const withAsyncLoader = (Component) => (props) => (
    <AsyncLoaderErrorBoundaryContainer>
        <Suspense fallback={<FullAppPreloader/>}>
            <Component {...props} />
        </Suspense>
    </AsyncLoaderErrorBoundaryContainer>
);

const LoadDashboardContainer = withAsyncLoader(DashboardContainer);
const LoadHomeContainer = withAsyncLoader(HomeContainer);
const LoadNotFound = withAsyncLoader(NotFound);

export const Router = () => (
    <BrowserRouter>
        <RouterEventListener actionOnLocationChange={ActionTypes.ROUTER_LOCATION_CHANGE}/>
        <Routes>
            <Route path={RoutePaths.ROOT} element={<LayoutContainer/>}>
                <Route index element={<LoadHomeContainer/>}/>
                <Route path={RoutePaths.DASHBOARD} element={<LoadDashboardContainer/>}/>
                <Route path="*" element={<LoadNotFound/>}/>
            </Route>
        </Routes>
    </BrowserRouter>
);