import React from 'react';
import { eventChannel } from 'redux-saga';
import { delay, call, spawn, put, race, select, takeEvery, takeLatest } from 'redux-saga/effects';
import isNil from 'ramda/src/isNil';

import Defaults from 'mangools-commons/dist/constants/Defaults';
import DownloaderService from 'mangools-commons/dist/services/DownloaderService';
import FileService from 'mangools-commons/dist/services/FileService';

import ErrorCodes, {
    INTERNAL_TIMEOUT_ERROR_PAYLOAD,
    INTERNAL_UNCAUGHT_ERROR_PAYLOAD,
} from 'mangools-commons/dist/constants/ErrorCodes';

import { APP_CONFIG } from 'mangools-commons/dist/configs/app'

import AnnouncementsSource from 'sources/AnnouncementsSource';
import ExportResultsService from 'services/ExportResultsService';
import HistorySource from 'sources/HistorySource';
import LocationSource from 'sources/LocationSource';
import ResultSource from 'sources/ResultSource';
import UrlDataSource from 'sources/UrlDataSource';
import VersionSource from 'sources/VersionSource';
import UnleashSource from 'sources/UnleashSource';

import { setDefaultLocation } from 'actions/defaultsActions';
import { accessTokenSelector } from 'selectors/userSelectors';
import { keywordSelector, paramsSelector } from 'selectors/paramsSelectors';
import { loadedPageCountSelector } from 'selectors/snapshotSelectors';

import {
    nextPageSelector,
    resultsSelector,
    globalMetricsSelector,
    fetchedSerpsCountSelector,
} from 'selectors/resultsSelectors';

import {
    comparingBoxUrlProtocolSelector,
    comparingBoxUrlSelector,
    newVersionNotificationShownSelector,
    selectedMetricFieldsSelector,
} from 'selectors/uiSelectors';

import {
    errorAction,
    errorAnnouncementsAction,
    errorHistoryAction,
    errorLocationsAction,
    errorMoreAction,
    errorUrlDataAction,
    exportingResultsAction,
    exportingResultsErrorAction,
    exportingResultsFinishedAction,
    fetchingAction,
    fetchingAnnouncementsAction,
    fetchingHistoryAction,
    fetchingLocationsAction,
    fetchingMoreAction,
    fetchingUrlDataAction,
    receivedAction,
    receivedAnnouncementsAction,
    receivedCurrentSnapshotPageSetAction,
    receivedEmptyAction,
    receivedHistoryAction,
    receivedRefetchAction,
    receivedLocationsAction,
    receivedMoreAction,
    receivedMoreEmptyAction,
    receivedUrlDataAction,
    skippedHistoryAction,
    receivedDeleteHistoryAction,
    errorDeleteHistoryAction,
    exportingSnapshotImageFinishedAction,
    errorSnapshotImageAction,
} from 'actions/dataActions';

import {
    hideLongerLoadingNotification,
    setNewVersionNotificationShown,
    showAccessDeniedMessage,
    showFailureMessage,
    showLongerLoadingNotification,
    showNoConnectionMessage,
    showPricingMessage,
} from 'actions/uiActions';

import { requestedLimitsAction, setUnleashSessionAction } from 'actions/userActions';
import { handleUncaught, logError } from 'sagas/errorSagas';
import { showInfoNotification } from 'sagas/uiSagas';

import ActionTypes from 'constants/ActionTypes';
import ExportTypes from 'constants/ExportTypes';
import Metrics from 'constants/Metrics';
import Strings from 'constants/Strings';
import { ErrorTypes } from 'constants/ErrorTypes';

const EXPORT_PREFIX = 'serpchecker_';
const EXPORT_SUFFIX = '_export';

const fetchResults = handleUncaught(
    function* fetchResults(options = { disableCache: false, retrying: false }, action) {
        yield put(fetchingAction());

        const accessToken = yield select(accessTokenSelector);
        const params = yield select(paramsSelector);
        const fetchedSerpsCount = yield select(fetchedSerpsCountSelector);
        const page = 0;
        const requestOptions = { accessToken, disableCache: options.disableCache, page, params, fetchedSerpsCount };

        const { result, _timeout } = yield race({
            result: call(options.disableCache ? ResultSource.getResetData : ResultSource.getData, requestOptions),
            _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
        });

        // Hide longer loading notification if this was retry
        // does not matter how it ended up.
        if (options.retrying === true) {
            yield put(hideLongerLoadingNotification());
        }

        if (!isNil(result)) {
            const { error, payload } = result;

            if (!error) {
                if (isNil(payload)) {
                    // No content - means no SERP results for this keyword
                    yield put(receivedEmptyAction());
                } else {
                    yield put(setDefaultLocation(payload.location));
                    yield put(options.disableCache ? receivedRefetchAction(payload) : receivedAction(payload));

                    if (options.disableCache) {
                        yield put(requestedLimitsAction());
                    }
                }
            } else {
                switch (payload.status) {
                    case ErrorCodes.FETCH_ERROR: {
                        if (options.retrying === true) {
                            yield put(errorAction(payload));
                            yield put(showNoConnectionMessage());
                        } else {
                            yield put(showLongerLoadingNotification());
                            // Wait for CONNECTION_RETRY_DELAY and try again
                            yield delay(Defaults.CONNECTION_RETRY_DELAY);
                            yield call(fetchResults, { disableCache: false, retrying: true }, action);
                        }
                        break;
                    }
                    case ErrorCodes.TOO_MANY_REQUESTS: {
                        yield put(errorAction(payload));

                        if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                            yield put(
                                showFailureMessage({ details: Strings.messages.failure.too_many_requests_error }),
                            );
                        } else if (payload.type === ErrorTypes.RATE_LIMIT) {
                            yield put(requestedLimitsAction());
                            yield put(showPricingMessage());
                        }

                        break;
                    }
                    case ErrorCodes.ACCESS_DENIED: {
                        yield put(errorAction(payload));
                        yield put(showAccessDeniedMessage());
                        break;
                    }
                    case ErrorCodes.UNATHORIZED: {
                        yield put(errorAction(payload));
                        break;
                    }
                    case ErrorCodes.REQUEST_TIMEOUT: {
                        if (options.retrying === true) {
                            yield put(errorAction(payload));
                            yield put(
                                showFailureMessage({ details: Strings.messages.failure.fetch_serp_results_error }),
                            );
                            yield call(logError, 'FetchResultsSaga', payload);
                        } else {
                            yield put(showLongerLoadingNotification());
                            yield call(fetchResults, { disableCache: false, retrying: true }, action);
                        }
                        break;
                    }
                    case ErrorCodes.SERVICE_UNAVAILABLE: {
                        if (options.retrying === true) {
                            yield put(errorAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                            yield call(logError, 'FetchResultsSaga', payload);
                        } else {
                            yield put(showLongerLoadingNotification());
                            yield call(fetchResults, { disableCache: false, retrying: true }, action);
                        }
                        break;
                    }
                    case ErrorCodes.INTERNAL_SERVER_ERROR:
                    default: {
                        if (options.retrying === true) {
                            yield put(errorAction(payload));
                            yield put(
                                showFailureMessage({ details: Strings.messages.failure.fetch_serp_results_error }),
                            );
                            yield call(logError, 'FetchResultsSaga', payload);
                        } else {
                            yield put(showLongerLoadingNotification());
                            yield call(fetchResults, { disableCache: false, retrying: true }, action);
                        }
                        break;
                    }
                }
            }
        } else {
            // Timeout
            yield put(errorAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
            yield put(showFailureMessage({ details: Strings.messages.failure.fetch_serp_results_error }));
            yield call(logError, 'FetchResultsSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
        }
    },
    function* onError() {
        yield put(errorAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.fetch_serp_results_error }));
    },
);

const fetchMoreResults = handleUncaught(
    function* fetchMoreResults(action, retrying = false) {
        yield put(fetchingMoreAction());

        const accessToken = yield select(accessTokenSelector);
        const params = yield select(paramsSelector);
        const page = yield select(nextPageSelector);
        const options = { accessToken, page, params };

        const { result, _timeout } = yield race({
            result: call(ResultSource.getData, options),
            _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
        });

        // Hide longer loading notification if this was retry
        // does not matter how it ended up.
        if (retrying === true) {
            yield put(hideLongerLoadingNotification());
        }

        if (!isNil(result)) {
            const { error, payload } = result;

            if (!error) {
                if (isNil(payload)) {
                    // No content - means no more SERP results for this keyword
                    yield put(receivedMoreEmptyAction());
                } else {
                    yield put(receivedMoreAction(payload, page));
                }
            } else {
                switch (payload.status) {
                    case ErrorCodes.FETCH_ERROR: {
                        if (retrying === true) {
                            yield put(errorMoreAction(payload));
                            yield put(showNoConnectionMessage());
                        } else {
                            yield put(showLongerLoadingNotification());
                            // Wait for CONNECTION_RETRY_DELAY and try again
                            yield delay(Defaults.CONNECTION_RETRY_DELAY);
                            yield call(fetchMoreResults, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.TOO_MANY_REQUESTS: {
                        yield put(errorMoreAction(payload));

                        if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                            yield put(
                                showFailureMessage({ details: Strings.messages.failure.too_many_requests_error }),
                            );
                        } else if (payload.type === ErrorTypes.RATE_LIMIT) {
                            yield put(requestedLimitsAction());
                            yield put(showPricingMessage());
                        }

                        break;
                    }
                    case ErrorCodes.ACCESS_DENIED: {
                        yield put(errorMoreAction(payload));
                        yield put(showAccessDeniedMessage());
                        break;
                    }
                    case ErrorCodes.UNATHORIZED: {
                        yield put(errorMoreAction(payload));
                        break;
                    }
                    case ErrorCodes.REQUEST_TIMEOUT: {
                        if (retrying === true) {
                            yield put(errorMoreAction(payload));
                            yield put(
                                showFailureMessage({ details: Strings.messages.failure.fetch_serp_results_error }),
                            );
                            yield call(logError, 'FetchMoreResultsSaga', payload);
                        } else {
                            yield put(showLongerLoadingNotification());
                            yield call(fetchMoreResults, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.SERVICE_UNAVAILABLE: {
                        if (retrying === true) {
                            yield put(errorMoreAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                            yield call(logError, 'FetchMoreResultsSaga', payload);
                        } else {
                            yield put(showLongerLoadingNotification());
                            yield call(fetchMoreResults, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.INTERNAL_SERVER_ERROR:
                    default: {
                        if (retrying === true) {
                            yield put(errorMoreAction(payload));
                            yield put(
                                showFailureMessage({ details: Strings.messages.failure.fetch_serp_results_error }),
                            );
                            yield call(logError, 'FetchMoreResultsSaga', payload);
                        } else {
                            yield put(showLongerLoadingNotification());
                            yield call(fetchMoreResults, action, true);
                        }
                        break;
                    }
                }
            }
        } else {
            // Timeout
            yield put(errorMoreAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
            yield put(showFailureMessage({ details: Strings.messages.failure.fetch_serp_results_error }));
            yield call(logError, 'FetchMoreResultsSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
        }
    },
    function* onError() {
        yield put(errorMoreAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.fetch_serp_results_error }));
    },
);

const fetchLocations = handleUncaught(
    function* fetchLocations(action, retrying = false) {
        yield put(fetchingLocationsAction());

        const accessToken = yield select(accessTokenSelector);
        const query = action.payload;
        const options = { accessToken, query };

        const { result, _timeout } = yield race({
            result: call(LocationSource.getData, options),
            _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
        });

        if (!isNil(result)) {
            const { error, payload } = result;

            if (!error) {
                yield put(receivedLocationsAction(payload));
            } else {
                switch (payload.status) {
                    case ErrorCodes.FETCH_ERROR: {
                        if (retrying === true) {
                            yield put(errorLocationsAction(payload));
                            yield put(showNoConnectionMessage());
                        } else {
                            // Wait for CONNECTION_RETRY_DELAY and try again
                            yield delay(Defaults.CONNECTION_RETRY_DELAY);
                            yield call(fetchLocations, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.ACCESS_DENIED: {
                        yield put(errorLocationsAction(payload));
                        yield put(showAccessDeniedMessage());
                        break;
                    }
                    case ErrorCodes.TOO_MANY_REQUESTS: {
                        yield put(errorLocationsAction(payload));

                        if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                            yield put(
                                showFailureMessage({ details: Strings.messages.failure.too_many_requests_error }),
                            );
                        }

                        break;
                    }
                    case ErrorCodes.SERVICE_UNAVAILABLE: {
                        if (retrying === true) {
                            yield put(errorLocationsAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                            yield call(logError, 'FetchLocationsSaga', payload);
                        } else {
                            yield call(fetchLocations, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.INTERNAL_SERVER_ERROR:
                    default: {
                        if (retrying === true) {
                            yield put(errorLocationsAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.fetch_locations_error }));
                            yield call(logError, 'FetchLocationsSaga', payload);
                        } else {
                            yield call(fetchLocations, action, true);
                        }
                        break;
                    }
                }
            }
        } else {
            // Timeout
            yield put(errorLocationsAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
            yield put(showFailureMessage({ details: Strings.messages.failure.fetch_locations_error }));
            yield call(logError, 'FetchLocationsSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
        }
    },
    function* onError() {
        yield put(errorLocationsAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.fetch_locations_error }));
    },
);

const setCurrentSnapshotPage = handleUncaught(function* setCurrentSnapshotPage(action) {
    const pageNumber = action.payload;
    const loadedCount = yield select(loadedPageCountSelector);

    if (pageNumber === loadedCount + 1) {
        yield call(fetchMoreResults, null, false);
    } else {
        yield put(receivedCurrentSnapshotPageSetAction(pageNumber));
    }
});

const fetchHistoryData = handleUncaught(
    function* fetchHistoryData(action, retrying = false) {
        const accessToken = yield select(accessTokenSelector);

        if (!isNil(accessToken)) {
            yield put(fetchingHistoryAction());

            const { result, _timeout } = yield race({
                result: call(HistorySource.getData, accessToken),
                _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    yield put(receivedHistoryAction(payload));
                } else {
                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying === true) {
                                yield put(errorHistoryAction(payload));
                                yield put(showNoConnectionMessage());
                            } else {
                                // Wait for CONNECTION_RETRY_DELAY and try again
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(fetchHistoryData, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.ACCESS_DENIED: {
                            yield put(errorHistoryAction(payload));
                            yield put(showAccessDeniedMessage());
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            yield put(errorHistoryAction(payload));

                            if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.too_many_requests_error,
                                    }),
                                );
                            }

                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying === true) {
                                yield put(errorHistoryAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                yield call(logError, 'FetchHistoryDataSaga', payload);
                            } else {
                                yield call(fetchHistoryData, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield put(errorHistoryAction(payload));
                                yield put(
                                    showFailureMessage({ details: Strings.messages.failure.fetch_history_error }),
                                );
                                yield call(logError, 'FetchHistoryDataSaga', payload);
                            } else {
                                yield call(fetchHistoryData, action, true);
                            }
                            break;
                        }
                    }
                }
            } else {
                yield put(errorHistoryAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield put(showFailureMessage({ details: Strings.messages.failure.fetch_history_error }));
                yield call(logError, 'FetchHistoryDataSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        } else {
            yield put(skippedHistoryAction());
        }
    },
    function* onError() {
        yield put(errorHistoryAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.fetch_history_error }));
    },
);

const deleteHistoryData = handleUncaught(function* deleteHistoryData(action, retrying = false) {
    const accessToken = yield select(accessTokenSelector);

    const { result, _timeout } = yield race({
        result: call(HistorySource.delete, { accessToken }),
        _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
    });

    if (!isNil(accessToken)) {
        if (!isNil(result)) {
            const { error, payload } = result;

            if (!error) {
                yield put(receivedDeleteHistoryAction());

                yield call(showInfoNotification, 'History was successfully cleared.');
            } else {
                switch (payload.status) {
                    case ErrorCodes.FETCH_ERROR: {
                        if (retrying === true) {
                            yield put(errorDeleteHistoryAction(payload));
                            yield put(showNoConnectionMessage());
                        } else {
                            // Wait for CONNECTION_RETRY_DELAY and try again
                            yield delay(Defaults.CONNECTION_RETRY_DELAY);
                            yield call(deleteHistoryData, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.ACCESS_DENIED: {
                        yield put(errorDeleteHistoryAction(payload));
                        yield put(showAccessDeniedMessage());
                        break;
                    }
                    case ErrorCodes.SERVICE_UNAVAILABLE: {
                        if (retrying === true) {
                            yield put(errorDeleteHistoryAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                            yield call(logError, 'DeleteHistorySaga', payload);
                        } else {
                            yield call(deleteHistoryData, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.TOO_MANY_REQUESTS: {
                        yield put(errorDeleteHistoryAction(payload));

                        if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                            yield put(
                                showFailureMessage({
                                    details: Strings.messages.failure.too_many_requests_error,
                                }),
                            );
                        }

                        break;
                    }
                    case ErrorCodes.INTERNAL_SERVER_ERROR:
                    default: {
                        if (retrying === true) {
                            yield put(errorDeleteHistoryAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.delete_history }));
                            yield call(logError, 'DeleteHistorySaga', payload);
                        } else {
                            yield call(deleteHistoryData, action, true);
                        }
                        break;
                    }
                }
            }
        } else {
            yield put(errorDeleteHistoryAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
            yield call(logError, 'DeleteHistorySaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
        }
    }
});

const exportResults = handleUncaught(
    function* exportResults(action) {
        const exportOptions = action.payload;
        const globalMetrics = exportOptions.includeGlobalMetrics ? yield select(globalMetricsSelector) : null;

        yield put(exportingResultsAction());

        const results = yield select(resultsSelector);
        const keyword = yield select(keywordSelector);
        const fileName = `${EXPORT_PREFIX}${FileService.sanitizeStringForFilename(keyword)}${EXPORT_SUFFIX}`;
        let fields = [];

        if (exportOptions.exportType === ExportTypes.ALL_METRICS) {
            fields = Metrics.map(metric => metric.propertyName);
        } else {
            fields = yield select(selectedMetricFieldsSelector);
        }

        const resultsCsv = yield call(ExportResultsService.export, {
            results,
            selectedMetricFields: fields,
            globalMetrics,
            includeSerpFeatures: exportOptions.includeSerpFeatures,
        });
        const success = yield call(DownloaderService.downloadCSV, fileName, resultsCsv);

        if (success) {
            yield put(exportingResultsFinishedAction());
            yield call(showInfoNotification, 'SERP results were successfully exported.');
        } else {
            yield put(showFailureMessage({ details: Strings.messages.failure.download_error }));
            yield put(exportingResultsErrorAction());
            yield call(logError, 'ExportResultsSaga|DownloaderService', {});
        }
    },
    function* onError() {
        yield put(showFailureMessage({ details: Strings.messages.failure.download_error }));
        yield put(exportingResultsErrorAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
    },
);

const fetchAnnouncements = handleUncaught(
    function* fetchAnnouncements(retrying = false) {
        yield put(fetchingAnnouncementsAction());

        const { result, _timeout } = yield race({
            result: call(AnnouncementsSource.getData),
            _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
        });

        if (!isNil(result)) {
            const { error, payload } = result;

            if (!error) {
                yield put(receivedAnnouncementsAction(payload));
            } else {
                switch (payload.status) {
                    case ErrorCodes.FETCH_ERROR: {
                        if (retrying === true) {
                            yield put(errorAnnouncementsAction(payload));
                            // yield put(showNoConnectionMessage());
                        } else {
                            // Wait for CONNECTION_RETRY_DELAY and try again
                            yield delay(Defaults.CONNECTION_RETRY_DELAY);
                            yield call(fetchAnnouncements, true);
                        }
                        break;
                    }
                    case ErrorCodes.ACCESS_DENIED: {
                        yield put(errorAnnouncementsAction(payload));
                        yield put(showAccessDeniedMessage());
                        break;
                    }
                    case ErrorCodes.TOO_MANY_REQUESTS: {
                        yield put(errorAnnouncementsAction(payload));

                        if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                            yield put(
                                showFailureMessage({
                                    details: Strings.messages.failure.too_many_requests_error,
                                }),
                            );
                        }

                        break;
                    }
                    case ErrorCodes.SERVICE_UNAVAILABLE: {
                        if (retrying === true) {
                            yield put(errorAnnouncementsAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                            yield call(logError, 'FetchAnnouncementsSaga', payload);
                        } else {
                            yield call(fetchAnnouncements, true);
                        }
                        break;
                    }
                    case ErrorCodes.INTERNAL_SERVER_ERROR:
                    default: {
                        if (retrying === true) {
                            yield put(errorAnnouncementsAction(payload));
                            yield put(
                                showFailureMessage({
                                    details: Strings.messages.failure.fetch_announcements_error,
                                }),
                            );
                            yield call(logError, 'FetchAnnouncementsSaga', payload);
                        } else {
                            yield call(fetchAnnouncements, true);
                        }
                        break;
                    }
                }
            }
        } else {
            yield put(errorAnnouncementsAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
            yield call(logError, 'FetchAnnouncementsSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
        }
    },
    function* onError() {
        yield put(errorAnnouncementsAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
    },
);

const fetchUrlData = handleUncaught(
    function* fetchUrlData(action, retrying = false) {
        yield put(fetchingUrlDataAction());
        const accessToken = yield select(accessTokenSelector);
        const url = yield select(comparingBoxUrlSelector);
        const protocol = yield select(comparingBoxUrlProtocolSelector);
        const urlWithProtocol = `${protocol.raw}${url}`;

        const { result, _timeout } = yield race({
            result: call(UrlDataSource.getData, { accessToken }, { url: urlWithProtocol }),
            _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
        });

        if (!isNil(result)) {
            const { error, payload } = result;

            if (!error) {
                yield put(receivedUrlDataAction(payload));
            } else {
                switch (payload.status) {
                    case ErrorCodes.FETCH_ERROR: {
                        if (retrying === true) {
                            yield put(errorUrlDataAction(payload));
                            yield put(showNoConnectionMessage());
                        } else {
                            // Wait for CONNECTION_RETRY_DELAY and try again
                            yield delay(Defaults.CONNECTION_RETRY_DELAY);
                            yield call(fetchUrlData, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.ACCESS_DENIED: {
                        yield put(errorUrlDataAction(payload));
                        yield put(showAccessDeniedMessage());
                        break;
                    }
                    case ErrorCodes.UNATHORIZED: {
                        yield put(errorUrlDataAction(payload));
                        break;
                    }
                    case ErrorCodes.TOO_MANY_REQUESTS: {
                        yield put(errorUrlDataAction(payload));

                        if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                            yield put(
                                showFailureMessage({ details: Strings.messages.failure.too_many_requests_error }),
                            );
                        } else if (payload.type === ErrorTypes.RATE_LIMIT) {
                            yield put(requestedLimitsAction());
                            yield put(showPricingMessage());
                        }

                        break;
                    }
                    case ErrorCodes.SERVICE_UNAVAILABLE: {
                        if (retrying === true) {
                            yield put(errorUrlDataAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                            yield call(logError, 'FetchUrlDataSaga', payload);
                        } else {
                            yield call(fetchUrlData, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.INTERNAL_SERVER_ERROR:
                    default: {
                        if (retrying === true) {
                            yield put(errorUrlDataAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.fetch_link_data_error }));
                            yield call(logError, 'FetchUrlDataSaga', payload);
                        } else {
                            yield call(fetchUrlData, action, true);
                        }
                        break;
                    }
                }
            }
        } else {
            // Timeout
            yield put(errorUrlDataAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
            yield put(showFailureMessage({ details: Strings.messages.failure.fetch_link_data_error }));
            yield call(logError, 'FetchUrlDataSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
        }
    },
    function* onError() {
        yield put(errorUrlDataAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.fetch_link_data_error }));
    },
);

const checkNewAppVersion = handleUncaught(function* checkNewAppVersion(action, retrying = false) {
    const { result, _timeout } = yield race({
        result: call(VersionSource.get),
        _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
    });

    if (!isNil(result)) {
        const { error, payload } = result;

        if (!error) {
            let newAppVersion;
            const currentAppVersion = APP_CONFIG.APP_VERSION;

            if (APP_CONFIG.production()) {
                newAppVersion = payload.serpchecker;
            } else {
                newAppVersion = payload.serpcheckerBeta;
            }

            if (!isNil(newAppVersion) && !isNil(currentAppVersion)) {
                const newAppVersionParts = newAppVersion.split('.');
                const newAppVersionMajor = parseInt(newAppVersionParts[0], 10);
                const newAppVersionMinor = parseInt(newAppVersionParts[1], 10);

                const currentAppVersionParts = currentAppVersion.split('.');
                const currentAppVersionMajor = parseInt(currentAppVersionParts[0], 10);
                const currentAppVersionMinor = parseInt(currentAppVersionParts[1], 10);

                if (newAppVersionMajor > currentAppVersionMajor || newAppVersionMinor > currentAppVersionMinor) {
                    const notificationShown = yield select(newVersionNotificationShownSelector);

                    // Only show if not already shown during this session
                    if (notificationShown === false) {
                        // We have an older version of application.
                        // Show a special notification.
                        yield call(
                            showInfoNotification,
                            <div>
                                <h4 class="font-14">
                                    UPDATE AVAILABLE 🤩
                                </h4>

                                <p>
                                    Please, reload the application to get newest features and prevent possible glitches.
                                </p>

                                <br />

                                <button
                                    class="mg-btn is-xsmall is-orange is-gradient mg-margin-t-5"
                                    onclick="location.reload();"
                                    type="button"
                                >
                                    Reload now
                                </button>
                            </div>);

                        yield put(setNewVersionNotificationShown());
                    }
                }
            }
        } else {
            switch (payload.status) {
                case ErrorCodes.FETCH_ERROR: {
                    if (retrying !== true) {
                        // Wait for CONNECTION_RETRY_DELAY and try again
                        yield delay(Defaults.CONNECTION_RETRY_DELAY);
                        yield call(checkNewAppVersion, action, true);
                    }
                    break;
                }
                case ErrorCodes.SERVICE_UNAVAILABLE: {
                    if (retrying === true) {
                        yield call(logError, 'FetchAppVersionDataSaga', payload);
                    } else {
                        yield call(checkNewAppVersion, action, true);
                    }
                    break;
                }
                case ErrorCodes.INTERNAL_SERVER_ERROR:
                default: {
                    if (retrying === true) {
                        yield call(logError, 'FetchAppVersionDataSaga', payload);
                    } else {
                        yield call(checkNewAppVersion, action, true);
                    }
                    break;
                }
            }
        }
    } else {
        yield call(logError, 'FetchAppVersionDataSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
    }
});

const checkUnleashSession = handleUncaught(function* checkUnleashSession(action, retrying = false) {
    const { result, _timeout } = yield race({
        result: call(UnleashSource.get),
        _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
    });

    if (!isNil(result)) {
        const { error, payload } = result;

        if (!error) {
            yield put(setUnleashSessionAction({ unleashSession: payload.sessionId }));
        } else {
            switch (payload.status) {
                case ErrorCodes.FETCH_ERROR: {
                    if (retrying !== true) {
                        // Wait for CONNECTION_RETRY_DELAY and try again
                        yield delay(Defaults.CONNECTION_RETRY_DELAY);
                        yield call(checkUnleashSession, action, true);
                    }
                    break;
                }
                case ErrorCodes.SERVICE_UNAVAILABLE: {
                    if (retrying === true) {
                        yield call(logError, 'FetchUnleashSessionDataSaga', payload);
                    } else {
                        yield call(checkUnleashSession, action, true);
                    }
                    break;
                }
                case ErrorCodes.INTERNAL_SERVER_ERROR:
                default: {
                    if (retrying === true) {
                        yield call(logError, 'FetchUnleashSessionDataSaga', payload);
                    } else {
                        yield call(checkUnleashSession, action, true);
                    }
                    break;
                }
            }
        }
    } else {
        yield call(logError, 'FetchUnleashSessionDataSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
    }
});

function newAppVersionCheckIntervalChannel() {
    return eventChannel(emitter => {
        const intervalId = setInterval(() => {
            emitter({
                intervalId,
            });
        }, Defaults.APP_VERSION_CHECK_INTERVAL);
        // }, 10 * 1000); // NOTE: Testing

        return () => {
            clearInterval(intervalId);
        };
    });
}

const fetchSnapshotImage = handleUncaught(
    function* fetchSnapshotImage(action, retrying = false) {
        const accessToken = yield select(accessTokenSelector);
        const { serpSnapshotId, pageNumber } = action.payload;

        const { result, _timeout } = yield race({
            result: call(ResultSource.getSnapshot, { accessToken, serpSnapshotId }),
            _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
        });

        if (!isNil(result)) {
            const { error, payload } = result;
            if (!error) {
                const keyword = yield select(keywordSelector);
                const fileName = `${EXPORT_PREFIX}${FileService.sanitizeStringForFilename(keyword)}_page${pageNumber}`;

                try {
                    DownloaderService.downloadBase64Image({ base64Data: payload.image, fileName });
                    yield put(exportingSnapshotImageFinishedAction());

                    yield call(showInfoNotification, 'SERP has been exported to image successfully.');
                } catch (e) {
                    yield put(errorSnapshotImageAction());
                    yield put(showFailureMessage({ details: Strings.messages.failure.download_snapshot }));
                    yield call(logError, 'FetchSnapshotImageSaga', payload);
                }
            } else {
                switch (payload.status) {
                    case ErrorCodes.FETCH_ERROR: {
                        if (retrying === true) {
                            yield put(errorSnapshotImageAction(payload));
                            yield put(showNoConnectionMessage());
                        } else {
                            // Wait for CONNECTION_RETRY_DELAY and try again
                            yield delay(Defaults.CONNECTION_RETRY_DELAY);
                            yield call(fetchSnapshotImage, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.ACCESS_DENIED: {
                        yield put(errorSnapshotImageAction(payload));
                        yield put(showAccessDeniedMessage());
                        break;
                    }
                    case ErrorCodes.UNATHORIZED: {
                        yield put(errorSnapshotImageAction(payload));
                        break;
                    }
                    case ErrorCodes.TOO_MANY_REQUESTS: {
                        yield put(errorSnapshotImageAction(payload));

                        if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                            yield put(
                                showFailureMessage({
                                    details: Strings.messages.failure.too_many_requests_error,
                                }),
                            );
                        }

                        break;
                    }
                    case ErrorCodes.SERVICE_UNAVAILABLE: {
                        if (retrying === true) {
                            yield put(errorSnapshotImageAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                            yield call(logError, 'FetchSnapshotSaga', payload);
                        } else {
                            yield call(fetchSnapshotImage, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.INTERNAL_SERVER_ERROR:
                    default: {
                        if (retrying === true) {
                            yield put(errorSnapshotImageAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.download_snapshot }));
                            yield call(logError, 'FetchSnapshotImageSaga', payload);
                        } else {
                            yield call(fetchSnapshotImage, action, true);
                        }
                        break;
                    }
                }
            }
        } else {
            yield put(errorSnapshotImageAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
            yield call(logError, 'FetchSnapshotImageSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
        }
    },
    function* onError() {
        yield put(errorSnapshotImageAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
    },
);

export function* fetchAfterLoginData() {
    yield spawn(fetchAnnouncements);
    yield spawn(checkNewAppVersion);
    yield spawn(checkUnleashSession);
}

function* watchNewAppVersionByInterval() {
    const channel = yield call(newAppVersionCheckIntervalChannel);
    yield takeEvery(channel, checkNewAppVersion);
}

function* watchResultsRequests() {
    yield takeLatest(ActionTypes.DATA_RESULTS_REQUESTED, fetchResults, {
        disableCache: false,
        retrying: false,
    });
}

function* watchResetResultsRequests() {
    yield takeLatest(ActionTypes.DATA_RESULTS_RESET_REQUESTED, fetchResults, {
        disableCache: true,
        retrying: false,
    });
}

function* watchHistoryRequests() {
    yield takeLatest(ActionTypes.DATA_HISTORY_REQUESTED, fetchHistoryData);
}

function* watchMoreResultsRequests() {
    yield takeLatest(ActionTypes.DATA_RESULTS_MORE_REQUESTED, fetchMoreResults);
}

function* watchLocationsRequests() {
    yield takeLatest(ActionTypes.DATA_LOCATIONS_REQUESTED, fetchLocations);
}

function* watchCurrentSnapshotSetRequests() {
    yield takeLatest(ActionTypes.DATA_CURRENT_SNAPSHOT_PAGE_SET_REQUESTED, setCurrentSnapshotPage);
}

function* watchResultsExportRequests() {
    yield takeLatest(ActionTypes.DATA_EXPORT_RESULTS_REQUESTED, exportResults);
}

function* watchUrlDataRequests() {
    yield takeLatest(ActionTypes.DATA_URL_DATA_REQUESTED, fetchUrlData);
}

function* watchHistoryDeleteRequests() {
    yield takeLatest(ActionTypes.DATA_HISTORY_DELETE_REQUESTED, deleteHistoryData);
}

function* watchSnapshotImageRequest() {
    yield takeEvery(ActionTypes.DATA_SNAPSHOT_IMAGE_REQUESTED, fetchSnapshotImage);
}

export function* watchDataRequests() {
    yield spawn(watchCurrentSnapshotSetRequests);
    yield spawn(watchHistoryRequests);
    yield spawn(watchLocationsRequests);
    yield spawn(watchMoreResultsRequests);
    yield spawn(watchResultsExportRequests);
    yield spawn(watchResultsRequests);
    yield spawn(watchResetResultsRequests);
    yield spawn(watchUrlDataRequests);
    yield spawn(watchHistoryDeleteRequests);
    yield spawn(watchSnapshotImageRequest);
    yield spawn(watchNewAppVersionByInterval);
}
