import { APP_CONFIG } from 'mangools-commons/dist/configs/app'
import queryString from 'query-string';
import { parse, parseResetData } from 'sources/parsers/ResultDataParser';

import ErrorCodes from 'mangools-commons/dist/constants/ErrorCodes';
import HumanService from 'mangools-commons/dist/services/HumanService';

// Constants
const ENDPOINT = '/serps';
const RESET_ENDPOINT = '/serps/reset';

/**
 * Data source class handling communication
 * with API for serp result list data.
 */
class ResultSource {
    static getData({ accessToken, disableCache, params, page }) {
        let query = null;

        if (disableCache === true) {
            query = queryString.stringify({
                cacheTime: 0,
                kw: params.keyword,
                location_id: params.locationId,
                page,
                parserType: 1,
                platform_id: params.platformId,
            });
        } else {
            query = queryString.stringify({
                kw: params.keyword,
                location_id: params.locationId,
                page,
                parserType: 1,
                platform_id: params.platformId,
            });
        }

        const requestURL = `${APP_CONFIG.API_HOST}${APP_CONFIG.API_PATH}${ENDPOINT}?${query}`;

        return fetch(requestURL, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'X-Access-Token': accessToken || '',
                'X-Human-Token': HumanService.gen(),
                'X-Mangools-Client': 'app',
            },
        })
            .then(response => {
                if (response.ok) {
                    if (response.status === 204) {
                        return {
                            error: false,
                            payload: null,
                        };
                    } else {
                        return response
                            .json()
                            .then(data => ({
                                error: false,
                                payload: parse(data),
                            }))
                            .catch(ex => ({
                                error: true,
                                payload: {
                                    requestURL,
                                    status: ErrorCodes.PARSE_ERROR,
                                    text: ex.toString(),
                                },
                            }));
                    }
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }

    static getResetData({ accessToken, disableCache, params, page, fetchedSerpsCount }) {
        let query = null;

        if (disableCache === true) {
            query = queryString.stringify({
                kw: params.keyword,
                location_id: params.locationId,
                page,
                parserType: 1,
                platform_id: params.platformId,
                serp_count: fetchedSerpsCount,
            });
        } else {
            query = queryString.stringify({
                kw: params.keyword,
                location_id: params.locationId,
                page,
                parserType: 1,
                platform_id: params.platformId,
                serp_count: fetchedSerpsCount,
            });
        }

        const requestURL = `${APP_CONFIG.API_HOST}${APP_CONFIG.API_PATH}${RESET_ENDPOINT}?${query}`;

        return fetch(requestURL, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'X-Access-Token': accessToken || '',
                'X-Human-Token': HumanService.gen(),
                'X-Mangools-Client': 'app',
            },
        })
            .then(response => {
                if (response.ok) {
                    if (response.status === 204) {
                        return {
                            error: false,
                            payload: null,
                        };
                    } else {
                        return response
                            .json()
                            .then(data => ({
                                error: false,
                                payload: parseResetData(data),
                            }))
                            .catch(ex => ({
                                error: true,
                                payload: {
                                    requestURL,
                                    status: ErrorCodes.PARSE_ERROR,
                                    text: ex.toString(),
                                },
                            }));
                    }
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }

    static getSnapshot({ accessToken, serpSnapshotId }) {
        const requestURL = `${APP_CONFIG.API_HOST}${APP_CONFIG.API_PATH}${ENDPOINT}/${serpSnapshotId}/snapshot`;

        return fetch(requestURL, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'X-Access-Token': accessToken || '',
                'X-Human-Token': HumanService.gen(),
                'X-Mangools-Client': 'app',
            },
        })
            .then(response => {
                if (response.ok) {
                    return response
                        .json()
                        .then(data => ({
                            error: false,
                            payload: data,
                        }))
                        .catch(ex => ({
                            payload: {
                                requestURL,
                                status: ErrorCodes.PARSE_ERROR,
                                text: ex.toString(),
                            },
                        }));
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    error: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }
}

export default ResultSource;
